import React from "react"
import { Button, Tabs, Tab, Form } from "react-bootstrap";
import { Link } from "gatsby"
import banner01 from '../../../images/contactPage/banner-mobile.jpg';
import banner02 from '../../../images/contactPage/banner-tablet.jpg';
import banner03 from '../../../images/contactPage/banner.jpg';
import './ContactBanner.scss';
const ContactBanner = (props) => {
    return (
        <section className="contact-banner">
            <div className="banner-img">
                <picture>
                    <source media="(min-width:992px)" srcSet={banner03} alt="banner" />
                    <source media="(min-width:768px)" srcSet={banner02} alt="banner" />
                    <img src={banner01} alt="banner" />
                </picture>
                <div className="banner-content">
                    <h1>Contact Us</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis auctor nibh at sollicitudin. Aenean mollis purus sit amet libero placerat condim.</p>

                   
                </div>
            </div>
        </section>)
};
export default ContactBanner;